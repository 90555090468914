// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-dr-m-chandrasekaran-js": () => import("./../../../src/pages/about-dr-m-chandrasekaran.js" /* webpackChunkName: "component---src-pages-about-dr-m-chandrasekaran-js" */),
  "component---src-pages-blog-blogs-details-js": () => import("./../../../src/pages/blog/blogs-details.js" /* webpackChunkName: "component---src-pages-blog-blogs-details-js" */),
  "component---src-pages-blog-blogs-left-sidebar-js": () => import("./../../../src/pages/blog/blogs-left-sidebar.js" /* webpackChunkName: "component---src-pages-blog-blogs-left-sidebar-js" */),
  "component---src-pages-blog-blogs-reguler-js": () => import("./../../../src/pages/blog/blogs-reguler.js" /* webpackChunkName: "component---src-pages-blog-blogs-reguler-js" */),
  "component---src-pages-blog-blogs-right-sidebar-js": () => import("./../../../src/pages/blog/blogs-right-sidebar.js" /* webpackChunkName: "component---src-pages-blog-blogs-right-sidebar-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-grid-portfolio-grid-2-full-js": () => import("./../../../src/pages/portfolio/grid/portfolio-grid-2-full.js" /* webpackChunkName: "component---src-pages-portfolio-grid-portfolio-grid-2-full-js" */),
  "component---src-pages-portfolio-grid-portfolio-grid-2-js": () => import("./../../../src/pages/portfolio/grid/portfolio-grid-2.js" /* webpackChunkName: "component---src-pages-portfolio-grid-portfolio-grid-2-js" */),
  "component---src-pages-portfolio-grid-portfolio-grid-2-no-gap-full-js": () => import("./../../../src/pages/portfolio/grid/portfolio-grid-2-no-gap-full.js" /* webpackChunkName: "component---src-pages-portfolio-grid-portfolio-grid-2-no-gap-full-js" */),
  "component---src-pages-portfolio-grid-portfolio-grid-2-no-gap-js": () => import("./../../../src/pages/portfolio/grid/portfolio-grid-2-no-gap.js" /* webpackChunkName: "component---src-pages-portfolio-grid-portfolio-grid-2-no-gap-js" */),
  "component---src-pages-portfolio-grid-portfolio-grid-3-full-js": () => import("./../../../src/pages/portfolio/grid/portfolio-grid-3-full.js" /* webpackChunkName: "component---src-pages-portfolio-grid-portfolio-grid-3-full-js" */),
  "component---src-pages-portfolio-grid-portfolio-grid-3-js": () => import("./../../../src/pages/portfolio/grid/portfolio-grid-3.js" /* webpackChunkName: "component---src-pages-portfolio-grid-portfolio-grid-3-js" */),
  "component---src-pages-portfolio-grid-portfolio-grid-3-no-gap-full-js": () => import("./../../../src/pages/portfolio/grid/portfolio-grid-3-no-gap-full.js" /* webpackChunkName: "component---src-pages-portfolio-grid-portfolio-grid-3-no-gap-full-js" */),
  "component---src-pages-portfolio-grid-portfolio-grid-3-no-gap-js": () => import("./../../../src/pages/portfolio/grid/portfolio-grid-3-no-gap.js" /* webpackChunkName: "component---src-pages-portfolio-grid-portfolio-grid-3-no-gap-js" */),
  "component---src-pages-portfolio-grid-portfolio-grid-4-full-js": () => import("./../../../src/pages/portfolio/grid/portfolio-grid-4-full.js" /* webpackChunkName: "component---src-pages-portfolio-grid-portfolio-grid-4-full-js" */),
  "component---src-pages-portfolio-grid-portfolio-grid-4-js": () => import("./../../../src/pages/portfolio/grid/portfolio-grid-4.js" /* webpackChunkName: "component---src-pages-portfolio-grid-portfolio-grid-4-js" */),
  "component---src-pages-portfolio-grid-portfolio-grid-4-no-gap-full-js": () => import("./../../../src/pages/portfolio/grid/portfolio-grid-4-no-gap-full.js" /* webpackChunkName: "component---src-pages-portfolio-grid-portfolio-grid-4-no-gap-full-js" */),
  "component---src-pages-portfolio-grid-portfolio-grid-4-no-gap-js": () => import("./../../../src/pages/portfolio/grid/portfolio-grid-4-no-gap.js" /* webpackChunkName: "component---src-pages-portfolio-grid-portfolio-grid-4-no-gap-js" */),
  "component---src-pages-portfolio-masonry-portfolio-masonary-2-full-js": () => import("./../../../src/pages/portfolio/masonry/portfolio-masonary-2-full.js" /* webpackChunkName: "component---src-pages-portfolio-masonry-portfolio-masonary-2-full-js" */),
  "component---src-pages-portfolio-masonry-portfolio-masonary-2-full-no-gap-js": () => import("./../../../src/pages/portfolio/masonry/portfolio-masonary-2-full-no-gap.js" /* webpackChunkName: "component---src-pages-portfolio-masonry-portfolio-masonary-2-full-no-gap-js" */),
  "component---src-pages-portfolio-masonry-portfolio-masonary-2-js": () => import("./../../../src/pages/portfolio/masonry/portfolio-masonary-2.js" /* webpackChunkName: "component---src-pages-portfolio-masonry-portfolio-masonary-2-js" */),
  "component---src-pages-portfolio-masonry-portfolio-masonary-2-no-gap-js": () => import("./../../../src/pages/portfolio/masonry/portfolio-masonary-2-no-gap.js" /* webpackChunkName: "component---src-pages-portfolio-masonry-portfolio-masonary-2-no-gap-js" */),
  "component---src-pages-portfolio-masonry-portfolio-masonary-3-full-js": () => import("./../../../src/pages/portfolio/masonry/portfolio-masonary-3-full.js" /* webpackChunkName: "component---src-pages-portfolio-masonry-portfolio-masonary-3-full-js" */),
  "component---src-pages-portfolio-masonry-portfolio-masonary-3-full-no-gap-js": () => import("./../../../src/pages/portfolio/masonry/portfolio-masonary-3-full-no-gap.js" /* webpackChunkName: "component---src-pages-portfolio-masonry-portfolio-masonary-3-full-no-gap-js" */),
  "component---src-pages-portfolio-masonry-portfolio-masonary-3-js": () => import("./../../../src/pages/portfolio/masonry/portfolio-masonary-3.js" /* webpackChunkName: "component---src-pages-portfolio-masonry-portfolio-masonary-3-js" */),
  "component---src-pages-portfolio-masonry-portfolio-masonary-3-no-gap-js": () => import("./../../../src/pages/portfolio/masonry/portfolio-masonary-3-no-gap.js" /* webpackChunkName: "component---src-pages-portfolio-masonry-portfolio-masonary-3-no-gap-js" */),
  "component---src-pages-portfolio-masonry-portfolio-masonary-4-full-js": () => import("./../../../src/pages/portfolio/masonry/portfolio-masonary-4-full.js" /* webpackChunkName: "component---src-pages-portfolio-masonry-portfolio-masonary-4-full-js" */),
  "component---src-pages-portfolio-masonry-portfolio-masonary-4-full-no-gap-js": () => import("./../../../src/pages/portfolio/masonry/portfolio-masonary-4-full-no-gap.js" /* webpackChunkName: "component---src-pages-portfolio-masonry-portfolio-masonary-4-full-no-gap-js" */),
  "component---src-pages-portfolio-masonry-portfolio-masonary-4-js": () => import("./../../../src/pages/portfolio/masonry/portfolio-masonary-4.js" /* webpackChunkName: "component---src-pages-portfolio-masonry-portfolio-masonary-4-js" */),
  "component---src-pages-portfolio-masonry-portfolio-masonary-4-no-gap-js": () => import("./../../../src/pages/portfolio/masonry/portfolio-masonary-4-no-gap.js" /* webpackChunkName: "component---src-pages-portfolio-masonry-portfolio-masonary-4-no-gap-js" */),
  "component---src-pages-portfolio-portfolio-details-1-js": () => import("./../../../src/pages/portfolio/portfolio-details-1.js" /* webpackChunkName: "component---src-pages-portfolio-portfolio-details-1-js" */),
  "component---src-pages-portfolio-portfolio-details-2-js": () => import("./../../../src/pages/portfolio/portfolio-details-2.js" /* webpackChunkName: "component---src-pages-portfolio-portfolio-details-2-js" */),
  "component---src-pages-portfolio-portfolio-details-3-js": () => import("./../../../src/pages/portfolio/portfolio-details-3.js" /* webpackChunkName: "component---src-pages-portfolio-portfolio-details-3-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-thyroid-faq-js": () => import("./../../../src/pages/thyroid-faq.js" /* webpackChunkName: "component---src-pages-thyroid-faq-js" */)
}


// import menuImage from '../assets/image/menu-image.webp'

export const menuItems = [
         {
           name: "/about-dr-m-chandrasekaran",
           label: "About Dr.MC",
           isExternal: true,
          //  items: [
          //    { name: "", label: "Marketing" },
          //    { name: "project", label: "Project Management" },
          //    { name: "it", label: "IT Services" },
          //    { name: "services", label: "Services" },
          //    { name: "agency", label: "Agency" },
          //    { name: "digital", label: "Digital Agency" },
          //    { name: "app", label: "App Presenting" },
          //    { name: "startup", label: "Startup" },
          //  ],
         },
         {
           name: "/thyroid-faq",
           label: "FAQs",
           isExternal: true,
          //  items: [
          //    { name: "innerpage/about-us", label: "About Us" },
          //    {
          //      name: "#",
          //      label: "Blog",
          //      items: [
          //        { name: "blog/blogs-reguler", label: "Blog Regular" },
          //        { name: "blog/blogs-left-sidebar", label: "Blog Left Sidebar" },
          //        { name: "blog/blogs-right-sidebar", label: "Blog Right Sidebar" },
          //        { name: "blog/blogs-details", label: "Blog Details" },
          //      ],
            //  },
            //  {
            //    name: "careers",
            //    label: "Publications",
            // //    items: [
            // //      { name: "innerpage/career", label: "Career" },
            // //      { name: "innerpage/career-details", label: "Career Details" },
            // //    ],
            //  },
            //  {
            //    name: "#",
            //    label: "Pricing",
            // //    items: [
            // //      { name: "innerpage/pricing/pricing-1", label: "Pricing One" },
            // //      { name: "innerpage/pricing/pricing-2", label: "Pricing Two" },
            // //    ],
            //  },
            //  {
            //    name: "#",
            //    label: "Contact",
            //   //  items: [
            //   //    { name: "innerpage/contact/contact-1", label: "Contact One" },
            //   //    { name: "innerpage/contact/contact-2", label: "Contact Two" },
            //   //  ],
            //  },
            //  {
            //    name: "#",
            //    label: "Account",
            //    items: [
            //      { name: "innerpage/sign-in", label: "Sign In" },
            //      { name: "innerpage/sign-up", label: "Sign Up" },
            //      { name: "innerpage/forgot", label: "Reset Password" },
            //    ],
            //  },
            //  {
            //    name: "Testimonial",
            //    label: "Testimonial",
            //    items: [
            //      { name: "innerpage/testimonials/testimonials-1", label: "Testimonial One" },
            //      { name: "innerpage/testimonials/testimonials-2", label: "Testimonial Two" },
            //    ],
            //  },
            //  {
            //    name: "utility",
            //    label: "Utility",
            //    items: [
            //      { name: "innerpage/faq", label: "FAQ" },
            //      { name: "innerpage/coming-soon", label: "Coming soon" },
            //      { name: "innerpage/terms", label: "Terms & Conditions" },
            //      { name: "innerpage/404", label: "404 Page" }
            //    ],
            //  },
          //  ],
         },
         {
          name: "/contact-us",
          label: "Book Appointment",
          isExternal: true,
        
         },
        //  {
        //    name: "megamenu",
        //    label: "Publications",
        //    megamenu:true,
        //    col:12,
        //    items: {
        //     //  image:{
        //     //   url:menuImage,
        //     //   col:"5",
        //     //  },
        //      col_1 : {
        //       title:"Research",
        //       items:[
        //         {
        //          name: "portfolio/grid/portfolio-grid-2",
        //          label: "Paper #1",
        //         },
        //         {
        //          name: "portfolio/grid/portfolio-grid-3",
        //          label: "Paper #2",
        //         },
        //         {
        //          name: "portfolio/grid/portfolio-grid-4",
        //          label: "Paper #3",
        //         },
        //         {
        //          name: "portfolio/grid/portfolio-grid-2-full",
        //          label: "Paper #4",
        //         },
        //         {
        //          name: "portfolio/grid/portfolio-grid-3-full",
        //          label: "Paper #5",
        //         },
        //         {
        //          name: "portfolio/grid/portfolio-grid-4-full",
        //          label: "Paper #6",
        //         },
        //        ]
        //      },
        //      col_2 : {
        //       title:"CME & Events",
        //       items:[
        //         {
        //          name: "portfolio/grid/portfolio-grid-2-no-gap",
        //          label: "Metamorphosis",
        //         },
        //         {
        //          name: "portfolio/grid/portfolio-grid-3-no-gap",
        //          label: "CME",
        //         },
        //         {
        //          name: "portfolio/grid/portfolio-grid-4-no-gap",
        //          label: "CME 2",
        //         },
        //         {
        //          name: "portfolio/grid/portfolio-grid-2-no-gap-full",
        //          label: "CME 3",
        //         },
        //         {
        //          name: "portfolio/grid/portfolio-grid-3-no-gap-full",
        //          label: "CME 4",
        //         },
        //         {
        //          name: "portfolio/grid/portfolio-grid-4-no-gap-full",
        //          label: "CME 5",
        //         },
        //        ]
        //      },
        //      col_3 : {
        //       title:"Media Coverage",
        //       items:[
        //         {
        //          name: "portfolio/masonry/portfolio-masonary-2",
        //          label: "Dhinamani",
        //         },
        //         {
        //          name: "portfolio/masonry/portfolio-masonary-3",
        //          label: "Journal",
        //         },
        //         {
        //          name: "portfolio/masonry/portfolio-masonary-4",
        //          label: "Vikatan",
        //         },
               
        //        ]
        //      },
        //      col_4 : {
        //       title:"Videos",
        //       items:[
        //         {
        //          name: "portfolio/portfolio-details-1",
        //          label: "Podhigai",
        //         },
               
        //        ]
        //      },
        //     //  col_6 : [
        //     //    {
        //     //     name: "supa",
        //     //     label: "supa",
        //     //    },
        //     //    {
        //     //     name: "Mega",
        //     //     label: "Mega",
        //     //    },
        //     //    {
        //     //     name: "menu",
        //     //     label: "Menu",
        //     //    },
        //     //    {
        //     //     name: "lele",
        //     //     label: "LEle",
        //     //    }
        //     //   ]
        //    }
        //  },
        
         {
           name: "/contact-us",
           label: "Contact Us",
           isExternal: true,
          
         },
       ];
export default menuItems;